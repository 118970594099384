<template>
    <v-sheet class="elevation-3 pa-5">

        <threshold-form></threshold-form>
        <threshold-list></threshold-list>

    </v-sheet>

</template>

<script>

import Form from "@/components/coupons/thresholds/Form";
import List from "@/components/coupons/thresholds/List";

    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
          'threshold-list': List,
          'threshold-form': Form
        }
    }
</script>

<style lang="scss" scoped>
</style>
