<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
        id="threshold-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{ $t('commons.actions.new') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>
        <v-container>

          <v-form
              class="pb-16"
              v-if="showForm"
              ref="form"
              v-model="valid"
              lazy-validation
          >

            <v-row>
              <v-col cols="4">
                <v-text-field
                    v-model="threshold.level"
                    :rules="requiredRules"
                    :label="$t('coupons.thresholds.fields.level')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                    v-model="threshold.rank"
                    :rules="requiredRules"
                    :label="$t('coupons.thresholds.fields.rank')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <countries
                    :country.sync="threshold.country"
                    :required-rules="requiredRules"
                    :disabled="isEditing"
                ></countries>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="threshold.label"
                    :rules="requiredRules"
                    :label="$t('coupons.thresholds.fields.label')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>
          </v-form>

          <v-divider></v-divider>

          <v-footer>
            <v-row id="footer-dialog">
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storethreshold"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>
          </v-footer>

        </v-container>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import clonedeep from 'lodash.clonedeep'
import {validationRules} from "@/mixins/validationRules";
import DialogButtons from "@/components/form/DialogButtons";
import {EventBus} from "@/event-bus";
import Countries from "@/components/form/Countries";


export default {
  name: "Form",

  mixins: [validationRules],

  data: () => ({
    isEditing: false,
    showForm: false,
    imgError: false,
    threshold: {},
    defaultthreshold: {
      level: '',
      label: '',
      rank: 0,
      language: '',
      country: ''
    },
    dialog: false,
    sending: false,
    valid: false,
  }),

  components: {
    'dialog-buttons': DialogButtons,
    Countries

  },

  computed: {
    ...mapGetters({
      isLoading: 'couponThreshold/loadingState',
    }),
    formTitle() {
      return (this.isEditing === true) ? this.$t('coupons.thresholds.edit.title') : this.$t('coupons.thresholds.create.title')
    },
  },

  mounted() {
    let self = this

    this.threshold = clonedeep(this.defaultthreshold)

    EventBus.$on('edit-coupon-threshold', function (threshold) {
      self.threshold = clonedeep(threshold)
      self.isEditing = true
      self.dialog = true
    })

    this.showForm = true
  },

  methods: {
    async storethreshold() {
      const valid = await this.$refs.form.validate()

      if (valid) {
        if (!this.isEditing) {
          await this.$store.dispatch('couponThreshold/storethreshold', this.threshold)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                this.close()
              })
              .catch(err => {
                console.log('create error err ', err)
              })
        } else {
          await this.$store.dispatch('couponThreshold/updatethreshold', this.threshold)
              .then(resp => {
                console.log('then close dialog resp ', resp)
                this.close()
              })
              .catch(() => {
              })
        }
      }
    },

    close() {
      this.dialog = false
      this.valid = true
      this.$refs.form.reset()
      this.$nextTick(() => {
        this.isEditing = false
        this.threshold = clonedeep(this.defaultthreshold)
      })
    },
  },

  watch: {
    "threshold.country": {
      handler: function (country, oldCountry) {
        if (country && country !== oldCountry && country !== this.missionParentCountry) {
          if (country === 'uk') {
            this.threshold.language = 'en'
          } else {
            this.threshold.language = country
          }
        }
      },
      deep: true
    }
  },
}
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}
</style>
