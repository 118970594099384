<template>
  <div>

    <v-divider class="my-3"></v-divider>

    <country-filter :loading="loading"
                    :country.sync="filters.country"
                    @change="applyFilter"
    />

    <v-data-table
        v-if="Object.keys(thresholds).length > 0"
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="thresholds"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        disable-pagination
    >

      <template v-slot:item.level="{ item }">
        <b>{{ item.level }}</b>
      </template>

      <template v-slot:item.created_at="{ item }">
        <b>{{ $parseDate(item.created_at) }}</b>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editCouponthreshold(item)"
        >
          mdi-pencil
        </v-icon>
      </template>


    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          @input="getCouponthresholds()"
          :disabled="isLoading"
      ></v-pagination>
    </div>

    <json-debug :objs="[{thresholds: thresholds}]"></json-debug>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus"
import CountryFilter from "@/components/filters/CountryFilter";

export default {
  name: "List",

  data() {
    return {
      filters: {},
      headers: [
        {text: '#', value: 'id', sortable: false, width: '5%'},
        {text: this.$t('coupons.thresholds.fields.level'), value: 'level', sortable: false, width: '20%'},
        {text: this.$t('coupons.thresholds.fields.label'), value: 'label', sortable: false},
        {text: this.$t('coupons.thresholds.fields.rank'), value: 'rank', sortable: false, width: '10%', align: "center"},
        {text: this.$t('commons.fields.country'), value: 'country', sortable: false, width: '10%', align: "center"},
        {text: this.$t('commons.fields.created_at'), value: 'created_at', sortable: false, width: '15%'},
        {text: '', value: 'actions', sortable: false, width: '4%', align: 'center'},
      ]
    }
  },

  components: {
    CountryFilter
  },

  mounted() {
    this.getCouponthresholds()
  },

  methods: {
    getCouponthresholds() {
      this.$store.dispatch('couponThreshold/fetchthresholds', this.filters)
    },

    editCouponthreshold (threshold) {
      console.log('edit threshold ' , threshold)
      this.isEditing = true
      EventBus.$emit('edit-coupon-threshold', threshold)
      this.dialog = true
    },

    applyFilter() {
      this.getCouponthresholds()
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'couponThreshold/loadingState',
      thresholds: 'couponThreshold/getthresholds',
      pagination: 'couponThreshold/getPagination'
    }),

    loading: {
      get() {
        return this.isLoading
      },
      set(v) {
        this.$store.dispatch('couponThreshold/setLoadingStatus', v)
      }
    },
  }
}
</script>

<style scoped>

</style>
